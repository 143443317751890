import React from "react";
import { Route, Switch } from "react-router-dom";

import CustomersList from "./customers/List";
import CustomersInfo from "./customers/Info";
import CustomersEdit from "./customers/Edit";

export default function HomePage() {
    return (
        <Switch>
            <Route exact path="/customers/add" component={CustomersEdit} />
            <Route exact path="/customers/:id/edit" component={CustomersEdit} />
            <Route exact path="/customers/:id" component={CustomersInfo} />
            <Route exact path="/customers" component={CustomersList} />
        </Switch>
    );
};
