/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl, AuthContext } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

export default ({ showHi, showAvatar, showBadge }) => {
  const { user } = useContext(AuthContext);

  return (
    <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        {showHi && (
          <span className="kt-header__topbar-welcome kt-hidden-mobile">
            Hi,
          </span>
        )}

        {showHi && (
          <span className="kt-header__topbar-username kt-hidden-mobile">
            {user.profile.email}
          </span>
        )}

        {showAvatar && <img alt="Pic" src={toAbsoluteUrl("/media/users/300_21.jpg")} />}

        {showBadge && (
          <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-">
            {user.profile.email[0]}
          </span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div
          className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
          }}
        >
          <div className="kt-user-card__avatar">
            <img alt="Pic" className="kt-hidden" src={user.pic} />
            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
              {user.profile.email[0]}
            </span>
          </div>
          <div className="kt-user-card__name">{user.profile.email}</div>
        </div>
        <div className="kt-notification">
          <a className="kt-notification__item">
            <div className="kt-notification__item-icon">
              <i className="flaticon2-calendar-3 kt-font-success" />
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                My Profile
                </div>
              <div className="kt-notification__item-time">
                Account settings and more
                </div>
            </div>
          </a>
          {/*<a className="kt-notification__item">
            <div className="kt-notification__item-icon">
              <i className="flaticon2-mail kt-font-warning" />
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                My Messages
                </div>
              <div className="kt-notification__item-time">
                Inbox and tasks
                </div>
            </div>
          </a>
          <a className="kt-notification__item">
            <div className="kt-notification__item-icon">
              <i className="flaticon2-rocket-1 kt-font-danger" />
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                My Activities
                </div>
              <div className="kt-notification__item-time">
                Logs and notifications
                </div>
            </div>
          </a>
          <a className="kt-notification__item">
            <div className="kt-notification__item-icon">
              <i className="flaticon2-hourglass kt-font-brand" />
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title kt-font-bold">
                My Tasks
                </div>
              <div className="kt-notification__item-time">
                latest tasks and projects
                </div>
            </div>
          </a>*/}
          <div className="kt-notification__custom">
            <Link
              to="/logout"
              className="btn btn-label-brand btn-sm btn-bold"
            >
              Sign Out
              </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
