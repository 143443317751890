import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

import FirebaseAuth from "../../services/auth";

const Auth = (props) => {
    const login = async () => {
        let uid = await FirebaseAuth.login.link.complete();
    };

    useEffect(() => {
        login();
    }, []);

    return (
        <h1>Please wait...</h1>
    )
};

export default Auth;