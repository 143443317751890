export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
      {
        title: "Customers",
        root: true,
        alignment: "left",
        page: "customers",
        translate: "MENU.CUSTOMERS"
      },
      {
        title: "Devices",
        root: true,
        alignment: "left",
        page: "devices",
        translate: "MENU.DEVICES"
      }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
      {
        title: "Customers",
        root: true,
        icon: "flaticon2-expand",
        page: "customers"
      },
      {
        title: "Devices",
        root: true,
        icon: "flaticon2-expand",
        page: "devices"
      }
    ]
  }
};
