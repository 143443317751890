import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./home/Builder";
import Dashboard from "./Dashboard";
import Customers from "./Customers";
import Devices from "./Devices";
import DocsPage from "./home/docs/DocsPage";
import { LayoutSplashScreen } from "../../_metronic";

const ReactBootstrapPage = lazy(() =>
  import("./home/react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <Route path="/dashboard" component={Dashboard} />
        <Route path="/customers" component={Customers} />
        <Route path="/devices" component={Devices} />
        
        <Route path="/builder" component={Builder} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
