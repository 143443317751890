import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../partials/content/Portlet";
import { metronic } from "../../_metronic";
import DeviceList from "./devices/List";

export default function Dashboard() {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-xl-12">
                    <DeviceList />
                </div>
            </div>
        </React.Fragment>
    )
};