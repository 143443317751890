import React from "react";
import { Route, Switch } from "react-router-dom";

import DevicesList from "./devices/List";
import DevicesInfo, { DevicesProxy } from "./devices/Info";
import DevicesEdit from "./devices/Edit";

export default function HomePage() {
    return (
        <Switch>
            <Route exact path="/devices/add" component={DevicesEdit} />
            <Route exact path="/devices/:id/edit" component={DevicesEdit} />
            {/*<Route exact path="/devices/:id/proxy/:target" component={DevicesProxy} />*/}
            <Route exact path="/devices/:id" component={DevicesInfo} />
            <Route exact path="/devices" component={DevicesList} />
        </Switch>
    );
};