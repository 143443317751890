import React, { useState, useEffect } from "react";
import _ from "lodash";

import Firebase from './firebase';

let connections = {};
const listener = () => {
    let connectionUpdate = updates => {
        updates.forEach(update => {
            let [action, item] = update;
            if (action === 'removed') {
                connections[item._id] = {
                    online: false,
                    _ts: +new Date()
                };
            } else {
                connections[item._id] = {
                    online: true,
                    ...item
                };
            }
        });
    };

    return Firebase.devices.connections.listen({ type: "vpn" }, connectionUpdate);
};

export default () => {
    return connections;
};

listener();