import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import _ from "lodash";
import moment from "moment";

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import useConnections from './../../services/connections';

import API from './../../services/api';

export const DeviceItem = ({ device }) => {
    let isDeviceOnline = _.get(device, '_conn.online', false);
    return (
        <div className="kt-widget5__item ">
            <div className="kt-widget5__content">
                <div className="kt-widget5__section">
                    <Breadcrumbs aria-label="Breadcrumb">
                        <Link to={`/devices/${device._id}/`} className="kt-widget5__title">{_.get(device, 'data.desc')}</Link>
                    </Breadcrumbs>
                    <div className="kt-widget5__info">
                        <span>Type:</span>
                        <span className="ml-1">{_.get(device, 'data.type')}</span>
                        {(isDeviceOnline) ? (
                            <React.Fragment>
                                <span>IP:</span>
                                <span className="ml-1 mr-1">{_.get(device, '_conn.networking.ip')}</span>
                                <span>/</span>
                                <span className="ml-1">{_.get(device, '_conn.type')}</span>
                                <span>DNS:</span>
                                <span className="ml-1"><a href={`https://${device._id}.device.cloud.apsis.si`.toLowerCase()}>{`${device._id}.device.cloud.apsis.si`.toLowerCase()}</a></span>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="kt-widget5__content">
                {(moment(device._cert.expiral, 'X').isBefore(moment().add(90, 'days'))) && (<span className="badge badge-warning text-white font-weight-bold text-uppercase mr-4" title="Certificate is about to expire">cert</span>)}
                <div className="kt-widget5__stats pr-0 mr-4">
                    <samp>{device._id}</samp>
                </div>
                <div className="kt-widget5__stats">
                    {(isDeviceOnline) ? (
                        <Button disabled variant="success">Online</Button>
                    ) : (
                        <Button disabled variant="outline-danger">Offline</Button>
                    )}
                </div>
            </div>
        </div>
    )
};

const HeaderMenu = () => {
    return (
        <React.Fragment>
            <Link to="/devices/add" className="btn btn-label-primary btn-bold btn-icon-h">
                Add new
            </Link>
        </React.Fragment>
    )
};

export default () => {
    let [devices, setDevices] = useState([]);
    let connections = useConnections();

    useEffect(() => {
        API.devices.getAll({}).then(data => {
            setDevices(data);
        });
    }, []);

    useEffect(() => {
        if (_.isEmpty(devices)) return;
        let allDevices = [...devices];
        allDevices.forEach(item => {
            let id = item._id;
            if (connections[id]) {
                item._conn = { ...connections[id] };
            } else {
                delete item._conn;
            }
        });
        setDevices(allDevices);
    }, [connections]);

    return (
        <React.Fragment>
            <LayoutSubheader title="Devices" menu={<HeaderMenu />} />
            <Portlet>
                <PortletBody>
                    <div className="kt-widget5">
                        {(devices.length === 0) ? (
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        ) : devices.map(device => <DeviceItem key={device._id} device={device} />)}
                    </div>
                </PortletBody>
            </Portlet>
        </React.Fragment>
    )
};