import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useFormik } from "formik";
import clsx from "clsx";

import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";

import API from './../../services/api';

const Editor = ({ create, edit, initValues, onSubmit }) => {
    const [ customers, setCustomers ] = useState(false);
    const types = [
        { label: "wago.v1", value: "wago.v1" },
        { label: "wago.cc100", value: "wago.cc100" },
        { label: "other", value: "other" }
    ];

    useEffect(() => {
        API.customers.getAll().then(data => {
            setCustomers(data);
        });
    }, []);

    const formik = useFormik({
        initialValues: initValues,
        onSubmit: onSubmit
    });

    if (!customers) return null;

    return (
        <Form
            noValidate={true}
            autoComplete="off"
            onSubmit={formik.handleSubmit}
        >
            {formik.status && (
                <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{formik.status}</div>
                </div>
            )}
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" name="desc" placeholder="Description" {...formik.getFieldProps('desc')} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="type" defaultValue={formik.getFieldProps('type').value}>
                            {types.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                {(create) && (<Col>
                    <Form.Group>
                        <Form.Label>Customer</Form.Label>
                        <Form.Control as="select" name="customer" {...formik.getFieldProps('customer')}>
                            {customers.map(option => (
                                <option key={option._id} value={option._id}>
                                    {option.data.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>)}
                <Col lg={1}>
                    <Button
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary`}
                        style={{ "marginTop": "26px" }}
                        onClick={formik.handleSubmit}
                    >
                        {(!formik.isSubmitting) ? "Submit" : "Sending"}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
};

const FormWrapper = ({ create, edit, match, history }) => {
    let [ready, setReady] = useState(false);
    let [values, setValues] = useState({
        desc: "",
        type: "wago.v1",
        customer: ""
    });

    const fetch = async () => {
        let device = await API.devices.getOne(match.params.id);
        setValues({
            ...device.data
        });
        setReady(true);
    };

    const onSubmit = (formValues, { setStatus, setSubmitting }) => {
        let {
            desc,
            type,
            customer
        } = formValues;

        if (create) {
            API.devices.entity.create({
                desc: desc,
                type: type,
                customer: customer
            }).then(data => {
                console.log(data);
                setSubmitting(false);
                history.push(`/devices/${data._id}`);
            });
        }
        if (edit) {
            API.devices.entity.edit(match.params.id, {
                desc: desc,
                type: type
            }).then(data => {
                setSubmitting(false);
                history.push(`/devices/${match.params.id}`);
            });
        }
    };

    useEffect(() => {
        if (create) {
            setReady(true);
        } else {
            fetch();
        }
    }, []);

    if (!ready) return null;
    return (
        <Editor initValues={values} onSubmit={onSubmit} create={create} edit={edit} />
    )
};

export default ({ match, history }) => (
    <React.Fragment>
        <Route exact path="/devices/add">
            <LayoutSubheader title="Add new device" />
        </Route>
        <Route exact path="/devices/:id/edit">
            <LayoutSubheader title="Edit device" />
        </Route>
        <Portlet>
            <PortletBody>
                <Route exact path="/devices/add">
                    <FormWrapper create history={history} />
                </Route>
                <Route exact path="/devices/:id/edit">
                    <FormWrapper edit history={history} match={match} />
                </Route>
            </PortletBody>
        </Portlet>
    </React.Fragment>
);