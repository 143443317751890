import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
//import { login } from "../../crud/auth.crud";

import FirebaseAuth from "../../services/auth";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  return (
    <React.Fragment>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          {(completed) ? (
            <div role="alert" className="alert alert-success">
              <div className="alert-text">{completed}</div>
            </div>
          ) : (
              <Formik
                initialValues = {{
                    email: ""
                  }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                } else if (
                  values.email.split('@')[1] !== 'apsis.si' && values.email.split('@')[1] !== '3dot.net'
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                enableLoading();
                await FirebaseAuth.login.link.send(`${window.location.protocol}//${window.location.hostname}/login/auth/`, values.email).then(() => {
                  disableLoading();
                  setCompleted(
                    intl.formatMessage({
                      id: "AUTH.FIREBASE.EMAIL_SENT"
                    })
                  );
                })
                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                      })
                    );
                  });
              }}
              >
                {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                  <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={handleSubmit}
                  >
                    {status ? (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    ) : null}

                    <div className="form-group">
                      <TextField
                        type="email"
                        label="Email"
                        margin="normal"
                        className="kt-width-full"
                        name="email"
                        placeholder="user@apsis.si"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>

                    <div className="kt-login__actions">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                          {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                          }
                        )}`}
                        style={loadingButtonStyle}
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default injectIntl(
  connect(
    null
  )(Login)
);
