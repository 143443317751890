import axios from 'axios';

const base = 'https://api.cloud.apsis.si';

axios.interceptors.request.use(async config => {
    config.headers.Authorization = await getToken();

    return config;
});

export default {
    handshake: (email, type) => {
        return axios.post(`${base}/handshake`, { email: email, type: type }).then(res => res.data);
    },
    customers: {
        getOne: async (customer) => {
            return axios.get(`${base}/customers/${customer}`).then(res => res.data);
        },
        getAll: async () => {
            return axios.get(`${base}/customers`).then(res => res.data);
        },
        entity: {
            create: (data) => {
                return $raw({ method: 'PUT', path: `${base}/customers`, payload: data }).then(res => res.data);
            },
            edit: (customer, data) => {
                return $raw({ method: 'POST', path: `${base}/customers/${customer}`, payload: data }).then(res => res.data);
            }
        },
        users: {
            get: ({customer, user}) => {
                if (user) return axios.get(`${base}/customers/${customer}/users/${user}`).then(res => res.data);

                return axios.get(`${base}/customers/${customer}/users`).then(res => res.data);
            }
        }
    },
    devices: {
        getOne: async (device) => {
            return axios.get(`${base}/devices/${device}`).then(res => res.data);
        },
        getAll: async ({ customer }) => {
            if (customer) return axios.get(`${base}/customers/${customer}/devices`).then(res => res.data);
            
            return axios.get(`${base}/devices`).then(res => res.data);
        },
        entity: {
            create: (data) => {
                return $raw({ method: 'PUT', path: `${base}/devices`, payload: data }).then(res => res.data);
            },
            edit: (device, data) => {
                return $raw({ method: 'POST', path: `${base}/devices/${device}`, payload: data }).then(res => res.data);
            }
        },
        activation: (device) => {
            return $raw({ method: 'PUT', path: `${base}/devices/${device}/activation` }).then(res => res.data);
        },
        proxy: (device) => {
            return axios.get(`${base}/devices/${device}/proxy`).then(res => res.data);
            //return $raw({ method: 'PUT', path: `${base}/devices/${device}/proxy` }).then(res => res.data);
        }
    },
    logs: {
        getAll: async (entity, item) => {
            return axios.get(`${base}/logs/${entity}/${item}`).then(res => res.data);
        }
    }
};

let getToken = () => {
    return import('./auth').then(r => {
        let token = r.default.status.get()._t;
        return token || null
        //console.log(r);
    }).catch(e => {
        console.error(e);
    });
}

let $get = async ({ path, payload = undefined }) => {
    let init = {
        method: 'get',
        url: path,
        baseURL: base.api,
        params: payload,
        headers: {
            "Authorization": await getToken()
        }
    };

    return axios(init);
};

let $post = async ({ path, payload }) => {
    let init = {
        method: 'post',
        url: path,
        baseURL: base.api,
        data: payload,
        headers: {
            "Authorization": await getToken()
        }
    };

    return axios(init);
};

let $raw = async ({ method, path, payload = undefined }) => {
    return axios({
        method: method,
        url: path,
        baseURL: base.api,
        data: payload,
        headers: {
            "Authorization": await getToken()
        }
    });
};