import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import _ from "lodash";

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";

import API from './../../services/api';

const CustomerItem = ({ customer }) => (
    <div className="kt-widget5__item ">
        <div className="kt-widget5__content">
            <div className="kt-widget5__section">
                <Link to={`/customers/${customer._id}/`} className="kt-widget5__title">{_.get(customer, 'data.name')}</Link>
            </div>
        </div>
        <div className="kt-widget5__content">
            <div className="kt-widget5__stats pr-0">
                <samp>{customer._id}</samp>
            </div>
        </div>
    </div>
);

const HeaderMenu = () => {
    return (
        <React.Fragment>
            <Link to="/customers/add" className="btn btn-label-primary btn-bold btn-icon-h">
                Add new
            </Link>
        </React.Fragment>
    )
};

export default () => {
    let [customers, setCustomers] = useState([]);

    useEffect(() => {
        API.customers.getAll().then(data => {
            setCustomers(data);
        });
    }, []);

    return (
        <React.Fragment>
            <LayoutSubheader title="Customers" menu={<HeaderMenu />} />
            <Portlet>
                <PortletBody>
                    <div className="kt-widget5">
                        {(customers.length === 0) ? (
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        ) : customers.map(customer => <CustomerItem key={customer._id} customer={customer} />)}
                    </div>
                </PortletBody>
            </Portlet>
        </React.Fragment>
    )
};