import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useFormik } from "formik";
import clsx from "clsx";

import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import { TextField } from "@material-ui/core";

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";

import API from './../../services/api';

const Editor = ({initValues, onSubmit}) => {
    const formik = useFormik({
        initialValues: initValues,
        onSubmit: onSubmit
    });

    return (
        <Form
            noValidate={true}
            autoComplete="off"
            onSubmit={formik.handleSubmit}
        >
            {formik.status && (
                <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{formik.status}</div>
                </div>
            )}

            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Name" {...formik.getFieldProps('name')} />
                    </Form.Group>
                </Col>
                <Col lg={1}>
                    <Button
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary`}
                        style={{ "marginTop": "26px" }}
                        onClick={formik.handleSubmit}
                    >
                        {(!formik.isSubmitting) ? "Submit" : "Sending"}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
};

const FormWrapper = ({ create, edit, match, history}) => {
    let [ready, setReady] = useState(false);
    let [values, setValues] = useState({
        name: ""
    });

    const fetch = async () => {
        let customer = await API.customers.getOne(match.params.id);
        setValues({
            ...customer.data
        });
        setReady(true);
    };

    const onSubmit = (formValues, { setStatus, setSubmitting }) => {
        let {
            name
        } = formValues;

        if (create) {
            API.customers.entity.create({
                name: name
            }).then(data => {
                setSubmitting(false);
                history.push(`/customers/${data._id}`);
            });
        }
        if (edit) {
            API.customers.entity.edit(match.params.id, {
                name: name
            }).then(data => {
                setSubmitting(false);
                history.push(`/customers/${match.params.id}`);
            });
        }
    };
    
    useEffect(() => {
        if (create) {
            setReady(true);
        } else {
            fetch();
        }
    }, []);

    if (!ready) return null;
    return (
        <Editor initValues={values} onSubmit={onSubmit} />
    )
};

export default ({ match, history }) => (
    <React.Fragment>
        <Route exact path="/customers/add">
            <LayoutSubheader title="Add new customer" />
        </Route>
        <Route exact path="/customers/:id/edit">
            <LayoutSubheader title="Edit customer" />
        </Route>
        <Portlet>
            <PortletBody>
                <Route exact path="/customers/add">
                    <FormWrapper create history={history} />
                </Route>
                <Route exact path="/customers/:id/edit">
                    <FormWrapper edit history={history} match={match} />
                </Route>
            </PortletBody>
        </Portlet>
    </React.Fragment>
);