import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useToasts } from 'react-toast-notifications';

import Spinner from 'react-bootstrap/Spinner';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";

import Firebase from './../../services/firebase';
import API from './../../services/api';
import Frame from './../../services/iframe';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

const Info = ({ device, connection, lastconnection }) => {
    let [status, setStatus] = useState((connection) ? connection : {
        online: false
    });

    useEffect(() => {
        Firebase.devices.connections.listen({ id: device._id, type: "vpn" }, update => {
            if (!update) return setStatus({
                online: false,
                _ts: +new Date()
            });
            return setStatus({
                online: true,
                ...update
            });
        });
    }, []);

    let lastTS = (() => {
        if (status.online) {
            return _.get(connection, '_ts', _.get(lastconnection, 'start', null));
        } else {
            return _.get(connection, '_ts', _.get(lastconnection, 'end', null));
        }
    })();

    return (
        <React.Fragment>
            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">{device._id}</h3>
                    <span className="kt-widget1__desc">{device.data.type}</span>
                </div>
                <Button disabled variant={(status.online) ? "success" : "danger"} size="sm">{(status.online) ? "Online" : "Offline"}</Button>
            </div>

            { (status.online) ? (
                <React.Fragment>
                    <div className="kt-widget1__item ng-star-inserted">
                        <div className="kt-widget1__info">
                            <h2>{status.networking.ip}</h2>
                            <h6><a href={`https://${device._id}.device.cloud.apsis.si`.toLowerCase()}>{`${device._id}.device.cloud.apsis.si`.toLowerCase()}</a></h6>
                            <span className="kt-widget1__desc">{status.type}</span>
                        </div>
                    </div>
                    <div className="kt-widget1__item ng-star-inserted">
                        <div className="kt-widget1__info">
                            <h2>{moment(lastTS, 'x').format('D.M.YYYY, HH:mm')}</h2>
                            <span className="kt-widget1__desc">Online since</span>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="kt-widget1__item ng-star-inserted">
                    <div className="kt-widget1__info">
                        <h2>{moment(lastTS, 'x').format('D.M.YYYY, HH:mm')}</h2>
                        <span className="kt-widget1__desc">Offline since</span>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

const Customers = ({ customers }) => {
    if (_.isEmpty(customers)) return null;

    return [
        customers.map(customer => (
            <div className="kt-widget1__item ng-star-inserted" key={customer._id}>
                <div className="kt-widget1__info">
                    <span className="kt-widget1__desc">Customer: <Link to={`/customers/${customer._id}/`}><strong>{customer.data.name}</strong></Link></span>
                </div>
                <samp>{customer._id}</samp>
            </div>
        ))
    ]
};

const Certificates = ({ certificates }) => {
    if (_.isEmpty(certificates)) return null;

    return (
        <React.Fragment>
            {certificates.map(item => (
                <div key={item._id} className="kt-widget1__item ng-star-inserted">
                    <div className="kt-widget1__info">
                        <span className="kt-widget1__desc" title="expires">Certificate: <strong>{moment(item.expiral, 'X').format('D.M.YYYY')}</strong></span>
                    </div>
                    <div>
                        {(item.deployed) && (<span className="badge badge-success font-weight-bold text-uppercase mr-2" title="deployed">dep</span>)}
                        {(item.active) && (<span className="badge badge-success font-weight-bold text-uppercase mr-2" title="active">act</span>)}
                        <samp>{item._id}</samp>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
};

const Activations = ({device, activations}) => {
    if (_.isEmpty(activations)) return null;

    return (
        <div className="kt-portlet">
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Pending activation</h3>
                </div>
            </div>
            <div className="kt-portlet__body">
                {activations.map((item, key) => (
                    <code key={key}>
                        {`wget -q -O - https://api.cloud.apsis.si/devices/${device}/activation/${item.code} | bash`}
                    </code>
                ))}
            </div>
        </div>
    )
};

const Log = ({device}) => {
    const [logs, setLogs] = useState([]);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        API.logs.getAll('device', device).then(items => {
            setLogs(items);
            setReady(true);
        });
    }, []);

    return (
        <div className="kt-portlet mb-0">
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Log entries</h3>
                </div>
            </div>
            <div className="kt-portlet__body">
                {(!ready) ? (
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                ) : (
                    <div className="kt-timeline-v2 ps ps--active-y">
                        <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                            {logs.map(item => (
                                <div key={item._ts} className="kt-timeline-v2__item">
                                    <span className="kt-timeline-v2__item-time">
                                        {moment(item._ts, 'x').format('D.M.YYYY, HH:mm')}
                                    </span>
                                    <div className="kt-timeline-v2__item-cricle">
                                        <i className={`fa fa-genderless kt-font-${item.level}`} />
                                    </div>
                                    <div className="kt-timeline-v2__item-text kt-padding-top-5">
                                        <Badge color="dark" pill className="text-uppercase">{item.data.category}</Badge>
                                        <code>{item.data.content}</code>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const HeaderMenu = ({match, reload, device, short}) => {
    if (!device) return null;

    if (short) return (
        <Link to={`/devices/${device._id}/`} className="btn btn-label-primary btn-bold btn-icon-h">
            To device
        </Link>
    )
    return (
        <React.Fragment>
            <ButtonCreateProxyLink device={device} reload={reload} />
            <ButtonCreateActivation device={device} reload={reload} />
            <Link to={`/devices/${device._id}/edit`} className="btn btn-label-primary btn-bold btn-icon-h">
                Edit
            </Link>
            {/*<Button className="btn btn-label-danger btn-bold btn-icon-h" disabled>
                Disable
            </Button>*/}
        </React.Fragment>
    )
};

const ButtonCreateProxyLink = ({ device, reload }) => {
    let [isLoading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const handleClick = async (target) => {
        setLoading(true);
        API.devices.proxy(device._id).then(data => {
            let url = data;
            switch (target) {
                case "webvisu":
                    url += 'webvisu/webvisu.htm';
                    break;
                case "dataplotter":
                    url += 'dataplotter/dataplotter.html';
                default:
                    break;
            }
            window.open(url);
        }).catch(err => {
            addToast("Device proxy could not be created", { appearance: 'warning' });
        }).then(() => setLoading(false));
    };

    return (
        (device._conn && device.data.proxy) ? (
            (!isLoading) ? (
                <Dropdown>
                    <Dropdown.Toggle>Open proxy interface</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {device.data.proxy.map(item => <Dropdown.Item key={item[0]} as="button" onClick={() => handleClick(item[0])}>{item[1]}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <Button className="btn btn-label-primary btn-bold btn-icon-h" disabled>Please wait <Spinner animation="border" size="sm" className="ml-2" /></Button>
            )
        ) : null
    )
};

const ButtonCreateActivation = ({device, reload}) => {
    let [ isLoading, setLoading ] = useState(false);
    const { addToast } = useToasts();
    
    const handleClick = async () => {
        setLoading(true);
        await API.devices.activation(device._id).then(data => {
            setLoading(false);
            addToast("Device provisioning link created", { appearance: 'success' });
            reload();
        }).catch(err => {
            setLoading(false);
            addToast("Device provisioning link could not be created", { appearance: 'warning' });
        });
    };

    return (
        <Button className="btn btn-label-primary btn-bold btn-icon-h" disabled={isLoading} onClick={!isLoading ? handleClick : null}>
            {isLoading ? 'Processing…' : 'Create provisioning link'}
        </Button>
    )
};

/*export const DevicesProxy = ({match}) => {
    const { addToast } = useToasts();
    const [device, setDevice] = useState(null);
    const [src, setSrc] = useState(null);
    const deviceID = match.params.id;
    const proxyTarget = match.params.target;

    useEffect(() => {
        API.devices.getOne(deviceID).then(item => {
            setDevice(item);
        });
    }, [deviceID]);

    useEffect(() => {
        API.devices.proxy(deviceID).then(data => {
            let url = data;
            switch (proxyTarget) {
                case "webvisu":
                    url += 'webvisu/webvisu.htm';
                    break;
                case "dataplotter":
                    url += 'dataplotter/dataplotter.html';
                default:
                    break;
            }
            setSrc(url);
        }).catch(err => {
            addToast("Device proxy could not be created", { appearance: 'warning' });
        });
    }, []);

    return (
        <React.Fragment>
            <LayoutSubheader title={_.get(device, 'data.desc', deviceID)} menu={<HeaderMenu match={match} device={device} short />} />
            <Portlet>
                <PortletBody fit={true}>
                    <Row className="row-col-separator-xl">
                        {(!src) ? (
                            <Col xl={12}>
                                <div className="p-5">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            </Col>
                        ) : (
                            <Col lg={12} className="text-center">
                                <Frame src={src} width="1024px" height="600px" style={{ border: "0" }} />
                            </Col>
                        )}
                    </Row>
                </PortletBody>
            </Portlet>
        </React.Fragment>
    )
}*/

export default ({match}) => {
    const [device, setDevice] = useState(null);
    const deviceID = match.params.id;

    const reloadMainInfo = () => {
        API.devices.getOne(deviceID).then(item => {
            setDevice(item);
        });
    };

    useEffect(() => {
        reloadMainInfo();
    }, [deviceID]);

    return (
        <React.Fragment>
            <LayoutSubheader title={_.get(device, 'data.desc', deviceID)} menu={<HeaderMenu match={match} device={device} reload={reloadMainInfo} />} />
            <Portlet>
                <PortletBody fit={true}>
                    <Row className="row-no-padding row-col-separator-xl">
                        {(!device) ? (
                            <Col xl={12}>
                                <div className="kt-widget1">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            </Col>
                        ) : (
                            <React.Fragment>
                                <Col lg={4}>
                                    <div className="kt-widget1">
                                        <Info device={device} connection={device._conn} lastconnection={device.data.lastconnection} />
                                        <Certificates device={device} certificates={device._certs} />
                                        <Customers device={device} customers={device._customers} />
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <Activations device={device._id} activations={device._activations} />
                                    <Log device={device._id} />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </PortletBody>
            </Portlet>
        </React.Fragment>
    )
};